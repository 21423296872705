/* custom-modal-styles.css 
rgb(0, 166, 147)
*/

/* Style the modal header background */
.custom-modal-header {
    background-color: rgb(0, 166, 147);
    color: #fff;
}

/* Style the modal body */
.custom-modal-body {
    padding: 20px;
}

/* Style the modal footer */
.custom-modal-footer {
    background-color: rgb(0, 166, 147);
}

/* Style the close button */
.custom-modal-close-button {
    color: #333;
    background-color: white;
}

/* Add any other custom styles as needed */