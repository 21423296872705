.user_row_contain{
    margin-top: 100px !important;
    align-items: center;
    justify-content: center;
}
.admin_login_form{
    width: 45% !important;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
}

.admin_logo_welcom{
    background-color: rgb(52, 195, 143);
    border-radius: 10px 10px 0 0;
}
.admin_login_label{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
}
.admin_login_welcom{
    font-weight: 500;
    font-size: 18px;
}
.admin_login_welcom2{
    font-size: 14px;
    font-weight: 500;
}
.admin_login_logo{
    width: 80px;
}
.admin_login_sibmit{
    background-color:rgb(52, 195, 143) ;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    padding: 10px;
}
.admin_login_sibmit:hover{
    background-color: rgb(9, 77, 52);
}
.admin_login_check:checked{
    background-color: rgb(0, 166, 147) ;
    border-color: rgb(0, 166, 147) ;
}
.user_row_contain>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 0 !important; 
  padding-left: 0 !important; 
  margin-top: var(--bs-gutter-y);
}
@media only screen and (max-width: 992px) {
    .admin_login_form{
        width: 70% !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .admin_login_form{
        width: 100% !important;
    }
  }