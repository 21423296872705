.isopen{
    margin-right: 230px;
}
.notopen{
    margin-right: 60px;
}
@media only screen and (max-width: 1000px) {
.notopen {
        margin-right: 50px;
    }
.isopen {
    margin-right: 50px;
}
}