/* add new */
/* end add new */
.admin_tab{
    color:#3ab09e !important;
    font-weight: 700 !important ;
}
.user_info_btn_produce{
    background-color: #3ab09e;
    border: none;
    border-radius: 5px;
    width: 100px;
    color: white;
}

.user_btn_right {
    border: 1px solid rgb(0, 166, 147) !important ;
    cursor: auto !important;
    border-radius: 0px 3px 3px 0px !important;
    font-size: 12px;
    color: rgb(0, 166, 147);
    height: 100%;
}

.user_btn_right:hover {
    border: 2px solid rgb(231, 162, 64) !important;
    color: rgb(231, 162, 64);
    font-size: 14px;
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: calc(var(--bs-border-width) * -1);
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.user_btn {
    border: 1px solid rgb(141, 138, 138) !important;
    cursor: auto !important;
    border-radius: 3px 0px 0px 3px !important;
    font-size: 12px !important;
    color: rgb(117, 116, 116);
    height: 100%;
}

.user_btn:hover {
    border: 1px solid rgb(141, 138, 138) !important ;
}
.user_phone_input {
    border-radius: 0 5px 5px 0 !important;
}
input,select,textarea{
    font-size: 12px;
}
label{
    font-size: 12px;
}

.admin_model_title{
    font-size: 16px  !important;
    font-weight: 700 !important ;
    color: white;
}
.admin_model_exit{
    color: white;
    margin-bottom: 20px !important;
}

.admin_nav_items {
    list-style-type: none;
    /* Removes the list item bullets */
    display: flex;
    /* Enables flexbox layout */
    justify-content: center;
    /* Centers items horizontally */
    padding: 0;
    /* Removes default padding */
    margin: 0;
    /* Removes default margin */
}

.admin_nav_items li {
    margin: 0 10px;
    margin-bottom: 20px;
    /* Spacing between list items */
}

.admin_nav_items button {
    background: none;
    /* Removes default button background */
    border: none;
    /* Removes default button border */
    color: inherit;
    /* Inherits font color from parent */
    font: inherit;
    /* Inherits font style from parent */
    cursor: pointer;
    /* Changes cursor to pointer on hover */
    outline: inherit;
    
    /* Inherits outline style (for accessibility) */
}

/* Additional Styling for Active Tab */
.admin_nav_items .active {
    font-weight: bold;
    color: white;
    /* Makes active tab text bold */
    /* You can add more styles for active tab here */
}