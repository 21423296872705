.modal-content {
    height: 500px !important;
    overflow: auto;
}

/* Style scrollbar */
.modal-content::-webkit-scrollbar {
    width: 12px;
    /* width of the scrollbar */
}

/* Track */
.modal-content::-webkit-scrollbar-track {
    background: rgb(52, 195, 143);
    /* transparent track */
}

/* Handle */
.modal-content::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 5px;
    /* white scrollbar handle */
}