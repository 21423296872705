.employee-custom-table,
.employee-custom-table th,
.employee-custom-table td {
    border: 2px solid rgb(236, 234, 234);
    /* Adds borders to all cells */
   
    
}
.employee-custom-table td{
padding: 20px !important;
}
.employee-custom-table table{
border-radius: 10px !important;
}


.admin_rules_thead
 {
    background-color: rgb(236, 234, 234) !important;
    /* Sets background color of the header to gray */
}

