.admin_sidebar_icon{
    font-size: 25px;
}
.admin_side_title{
    font-size: 14px !important;
    font-weight: 700 !important;
}
.admin_footer_copy{
    font-size: 12px;
}

@media only screen and (max-width: 600px) {
.admin_footer_copy {
        font-size: 9px;
    }
}
.support-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0 6px;
    margin-left: 5px;
    font-size: 0.8rem;
    vertical-align: super;
  }
  