.referee_model_nav{
    background-color: rgb(0, 166, 147);
}
.refree_nav_item{
    color: white;
}
.refree_nav_item:hover{
    border: none;
    color: white;
}
.modal-dialog-scrollable{
    height: 85%;
}
.refree_btn_close{
    background-color: rgb(0, 166, 147);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
}
.referr_title_final{
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: rgb(0, 166, 147);
}
.referee_name_user{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    background-color: rgb(17, 109, 57);
    color: white;
    font-size: 12px;
    font-weight: 700;
    border-radius: 2px;
    align-items: center;
    padding: 3px 0px;
}
.refree_degree_final{
    border: none;
    color: white;
    background-color:rgb(17, 109, 57);
    padding: 8px;
    border-radius: 5px;
}
 