

.user_account_public_contain {
    border: 1px solid rgb(211, 208, 208);
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    font-weight: 500;
    font-size: 12px;
    color: rgb(65, 65, 65);
}



.user_account_form_policy>div>label {
    color: rgb(0, 166, 147);
    font-weight: 500;
    font-size: 13px;
}

label>i {
    font-size: 5px;
}

.form-group input:focus {
    box-shadow: 0 0 4px rgb(0, 166, 147);
}

.form-group select:focus {
    box-shadow: 0 0 4px rgb(0, 166, 147);
}

.user_account_btn {
    border: none;
    border-radius: 5px;
    background-color: rgb(0, 166, 147);
    color: white;
    padding: 8px;
    font-weight: 500;
}

.user_account_confirm_message {
    color: rgb(238, 13, 13);
    font-weight: 500;
}

.user_account_data {
    font-size: 12px;
    font-weight: 500;
    color: rgb(114, 113, 113);
}

.swal2-confirm {
    background-color: rgb(0, 166, 147) !important;
    /* Change the background color */
}