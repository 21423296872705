/* colors
rgb(52, 195, 143); main color light green
rgb(9, 77, 52); second color dark green
rgb(71, 70, 70);  title gray
rgb(107, 105, 105); subtitle graywhite
rgb(233, 226, 226); for table border
rgb(239, 242, 247) light blue for table
rgb(52, 58, 64) dark
rgb(244, 106, 106) for del
rgb(52, 195, 143) for edit
rgb(52, 58, 64) for show
*/
.row{
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

    * {
      font-family: 'Alexandria', sans-serif;
      font-size: small;
      font-weight: small;
    }

    /* page main items */

    .admin_page_header{
      margin-bottom: 20px;
    }
        .admin_page_title{
          color: rgb(71, 70, 70);
          font-weight: 500;
          font-size: 16px;
        }

        .admin_page_subtitle{
          font-size: 12px;
          color: rgb(107, 105, 105);
        }

        .admin_page_content{
          background-color: white;
          width: 100%;
          padding: 20px !important;
          border-radius: 10px;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }

        .admin_page_btn_new{
          background-color: rgb(52, 195, 143);
          border: none;
          border-radius: 5px;
          color: white;
          height: 40px;
          padding-left: 10px !important;
          padding-right: 10px !important;
        }

      .admin_page_btn_new:hover{
       background-color: rgb(9, 77, 52);
      }
      .admin_table{
        border: 1px solid rgb(238, 236, 236);
      }

      .admin_table  th , .admin_table td {
        border: 1px solid rgb(233, 226, 226);
        padding: 10px !important;
      }
      .admin_table_thead th{
        background-color: rgb(239, 242, 247) !important;
        font-size: 12px;
        font-weight: 400;
      }
      .admin_table td{
        font-size: 11px;
        font-weight: 300;
        color: rgb(107, 105, 105) !important;
      }
      
    .admin_table_open_btn {
      font-size: 9px !important;
      background-color: rgb(239, 242, 247) !important;
      border: none !important;
    }
    .admin_table_open_btn2{
        font-size: 9px !important;
    }
    .admin_table_delete_btn , .admin_table_edit_btn ,.admin_table_show_btn{
      height: 35px;
      font-size: 10px;
      background-color: transparent;
    }
    .admin_table_delete_btn{
      border: 1px solid rgb(244, 106, 106);
      color:rgb(244, 106, 106);
      border-radius: 0px 3px 3px 0px;
    }
    .admin_table_edit_btn {
      border: 1px solid rgb(52, 195, 143);
      color: rgb(52, 195, 143);
    }
    .admin_table_show_btn {
      border: 1px solid rgb(52, 58, 64);
      color: rgb(52, 58, 64);
      border-radius: 3px  0px 0px 3px;
    }
    .refree_btn_rev{
            border: 1px solid rgb(52, 58, 64);
            color: rgb(52, 58, 64);
            border-radius: 3px;
            background-color: transparent;
            padding: 5px 10px;
    }

    .admin_table_delete_btn:hover{
    background-color: rgb(244, 106, 106);
    color: white;
    }

  .admin_table_edit_btn:hover {
    background-color: rgb(52, 195, 143);
    color: white;
  }
  .admin_table_show_btn:hover,.refree_btn_rev:hover {
    background-color: rgb(52, 58, 64);
    color: white;
  }

  input::placeholder{
    font-size: 12px !important;
  }
  input,select,textarea{
    font-size: 12px !important;
   
  }
  select > option{
    font-size: 12px !important;
  }
.admi_toggle_switch input{
font-size: 15px !important;
}
.admi_toggle_switch input:checked {
  background-color: rgb(52, 195, 143) !important;
  border-color: rgb(52, 195, 143);
}
.admi_toggle_switch input:focus{
  border-color: rgb(52, 195, 143);
  box-shadow: 0 0 3px 2px rgb(52, 195, 143) !important;
}
  